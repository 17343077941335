//Filter label
.filter-label {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 128.571%;
  color: var(--gray);
}
//

.main-error {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--error-bg);
  padding: 12px;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.571%;

  color: var(--error-input);

  & .error-icon {
    width: 16px;
    height: 16px;
  }
}

.input-with-error-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  width: 100%;
}

.small-error-text-field {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 116.667%;

  color: var(--error-input);
}

.form-label {
  position: relative;
  color: var(--gray);
  display: flex;
  gap: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.285;

  width: 180px;
  flex-shrink: 0;
  justify-content: flex-start;
}

.project-optional-label {
  display: flex;
  gap: 4px;
}

.project-optional-text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.571%;

  color: var(--primary-gray);

  &--right {
    text-align: right;
  }
}

.field-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;

  @media screen and (max-width: 749px){
    flex-direction: column;
  }
}


.flex-field-wrapper {
  display: flex;
  gap:8px;
}

.field-wrapper-shorter {
  width: 100%;
}

.backdrop.form-invalid:has(.form-create.ng-invalid) {
  overflow-y: scroll;
}
.form-input-modal {
  border: 1px solid;
  border-color: var(--light-gray-inputs-border);
  border-radius: 8px;
  padding: 15px 16px 16px;
  box-shadow: none;
  transition: border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
  height: 42px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.285;

  &::placeholder {
    color: var(--placeholder);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.285;
  }

  &:hover {
    border-color: var(--hover-input);
  }

  &:focus {
    border-color: var(--focus-input);
    box-shadow: 0 4px 6px 0 rgba(14, 19, 29, 0.08);
    outline: none;
  }

  &--disabled, &:disabled {
    border: 1px solid var(--lines-light-gray-border);
    background: var(--primary-light-gray);
    color: var(--primary-gray);
    cursor: auto;

    &:hover {
      border-color: var(--lines-light-gray-border);
      color: var(--primary-gray);
    }

    &:focus {
      box-shadow: none;
      outline: none;
      border-color: var(--lines-light-gray-border);
      color: var(--primary-gray);
    }
  }

  &--enabled, &:enabled {
    border: 1px solid;
    border-color: var(--light-gray-inputs-border);
    background: white;
    color: var(--black);

    &:hover {
      border-color: var(--hover-input);
      color: var(--black);
    }

    &:focus {
      border-color: var(--focus-input);
      box-shadow: 0 4px 6px 0 rgba(14, 19, 29, 0.08);
      color: var(--black);
      outline: none;
    }
  }

  .form-invite.ng-submitted &.ng-invalid {
    border-color: var(--error-input);
  }
}
form.ng-submitted app-custom-datepicker.ng-invalid input {
  border-color: var(--error-input);
}

form.ng-submitted app-material-custom-selector.ng-invalid input {
  border-color: var(--error-input);
}

form.ng-submitted app-material-custom-selector-with-colored-options.ng-invalid button {
  border-color: var(--error-input);
}



//Form button

.form-btn-wrapper {
  border-top: 1px solid var(--light-gray-inputs-border);
  background-color: var(--bg-option);
  padding: 24px;

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  @media screen and (max-width: 749px) {
    justify-content: flex-start;
  }
}

.btn-cancel{
  background-color: var(--bg-option);
  border: 1px solid var(--light-gray-inputs-border);
  border-radius: 8px;
  padding: 16px 24px;
  cursor: pointer;
  color: var(--black);

  transition: background-color 250ms ease-in-out, border 250ms ease-in-out;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 128.571%;

  &:hover {
    background-color: var(--white);
    border: 1px solid var(--hover-input);

  }

  @media screen and (max-width: 749px) {
    width: 50%;
  }
}

.phone-with-code-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.btn-invite {
  white-space: nowrap;
  @extend .btn-cancel;
  background-color: var(--primary-dark);
  color: var(--white);
  border: 1px solid var(--primary-dark);

  &:hover {
    background-color: var(--form-btn-dark-hover);
    border: 1px solid var(--primary-dark);
  }

  &:disabled {
    border: 1px solid var(--lines-light-gray-border);
    opacity: 0.2;
    //background: var(--primary-light-gray);
    color: var(--white);
    cursor: auto;

    &:hover {
      border-color: var(--lines-light-gray-border);
      color: var(--white);
      opacity: 0.2;
    }

    &:focus {
      box-shadow: none;
      outline: none;
      border-color: var(--lines-light-gray-border);
      color: var(--white);
      opacity: 0.2;
    }
  }

  @media screen and (max-width: 749px) {
    width: 50%;
  }
}
.mat-mdc-option {
  min-height: 42px;
}
.mdc-list-item__primary-text {
  font-family: 'Inter', sans-serif!important;
  font-size: 14px!important;
  font-weight: 400!important;
}

textarea {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}
