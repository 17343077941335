
/* You can add global styles to this file, and also import other style files */
@import "normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import "src/app/components/admin/components/form-create-common";
@import "src/app/components/admin/components/modal-window";
@import "src/app/components/admin/components/ng-select-common";
@import "src/app/components/admin/components/settings-page-common";
@import 'quill/dist/quill.snow.css';
@import "styles/scrollbar";
@import "styles/typograhy";
@import "styles/buttons";
@import "node_modules/tui-image-editor/dist/tui-image-editor.css";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

html,body {
  overflow-x: hidden;
  min-height: 100vh;
}
body:has(.backdrop[show=true]) {
  overflow: hidden;

  .customize-menu {
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }
}

body:has(.dashboard-wrapper) {
  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: 8px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--light-gray-inputs-border);
    border-radius: 8px;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

}

body:has(.app-wrapper[isAuthPage='true']) {
  display: flex;
  flex-direction: column;
}


body:has(.dots-actions[editMenuOpen]) {
  .pagination-wrapper-with-show {
    //margin-top: 24px;
  }
}

body:has(.task-status-td[openMenu]) {
  .pagination-wrapper-with-show {
    //margin-top: 24px;
  }
}


body:has(.slider-wrapper) {
  overflow-x: hidden;
}

html:has(.email-notif-settings-wrapper),
body:has(.email-notif-settings-wrapper) {
  overflow-y: hidden;
  overflow-x: unset!important;
}

//html,body {
//  @media screen and (max-width: 549px) {
//    min-width: 1680px;
//  }
//}

//body:has(.swiper.unit-swiper) {
//  overflow-y: hidden;
//}

body:has(.floors-with-area-progress-wrapper) {
  overflow: hidden;
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}
label {
  margin: 0;
}

img {
  display: block;
  width: 100%;
}

button:focus {
    outline: none;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media screen and (min-width: 768px){
    width: 768px;
  }

  @media screen and (min-width: 1280px){
    width: 1280px;
  }
}



//Vars
//text color
:root {
  --black: #000;
  --white: #fff;
  --gray: #3D3D3D;
  --primary-gray: #9FA1A4;
  --primary-light-gray:  #FAFBFC;
  --accent-orange: #FF4E00;
  --hover-orange: #FD6D2D;


  //Sidebar
  --primary-dark: #2D2F33;
  --hover-link: #242429;
  --hover-link-dark: #1C1C20;
  --bg-line: #27272D;
  --nav-icon-default: #9FA1A4;

  //Sub-header
  --user-menu-hover-bg: #F4F4F4;
  --lines-light-gray-border: #F0F2F5;
  --logout-icon-default-stroke: #292D32;

  //Form
  --light-gray-inputs-border: #D8D8D8;
  --hover-input: #B4B4B4;
  --focus-input: #8F8F8F;
  --error-input: #E41616;
  --placeholder: #9FA1A4;
  --filterBtn-selected-hover: #FBECE5;

  --form-btn-dark-hover: #242429;

  --error-bg: #FBECE5;

  //Select
  --bg-option: #FAFBFC;

  //Button dark
  --bg-btn-dark: #090814;
  --bg-btn-dark-hover: #545454;

  --btn-save-comment: #1C1C20;

  //Icon password
  --icon-stroke-focus: #3D3D3D;

  //Table
  --bg-type: #F0F2F6;
  --bg-table-hover: #FAFBFC;
  --bg-table-hover-darker: #F7F7F8;

  --bg-role-admin: #FFC2A9;
  --bg-role-member: #DECDF3;
  --bg-role-manager: #FFC2A9;
  --bg-role-viewer: #B9EDED;

  --statusBar: #F0F2F7;

  //Modal
  --backdrop: rgba(0, 0, 0, 0.40);

  //Notification
  --notify-bg-success: #E1F3DB;
  --notify-bg-error: #F3DBDB;
  --icon-bg-success: #5DC838;

  //Datapicker
  --calendar-title: #000000;
  --dayHover: #FBECE5;

  //Button outline red
  --btn-red: #E41616;
  --btn-red-hover:  #820B0B;
  --btn-light-red-hover: #e94444;

  //Tabs project
  --bg-tab-hover: #F0F2F6;

  //Tasks-status
  --todo-status: #FDDDB8;
  --inprogress-status: #B9EDED;
  --ready-status: #DECDF3;
  --done-status: #E1F3DB;

  //Units
  --unit-bg: #F0F2F6;
  --unit-placeholder: #FAFBFC;
  --active-floor-btn: #242429;

  --room-block-bg: #E8EBF0;

  //Rooms, Areas

  --area-bg: #E5E8ED;

  //Area Status
  --area-not-use-bg: #F0F2F6;
  --area-todo-border: #D2DAE5;
  --area-inprogress-bg: #37A702;
  --area-done-bg: #116A1F;
  --table-main-title-bg: #484E57;
  --area-name-bg: #8E9DB3;
  --unit-name-bg: #8E9DB3;

  //Comments icon
  --comment-icon: #667085;

  //Scrollbar
  --scrollbar-width: 8px;

  //Material submittal and order statuses colors
  --material-status-red: #FDB8B8;
  --material-status-dark-red: #E3443A;
  --material-status-orange: #FDDDB8;
  --material-status-blue: #B9EDED;
  --material-status-light-green: #E1F3DB;
  --material-status-green: #37A702;
}

//Empty list

.empty-list-wrapper {
  background-color: var(--bg-table-hover);
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.empty-list-text {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: var(--primary-gray);
}

.empty-list-icon {
  width: 400px;
  height: 90px;
}
.loader-spin {
  width: fit-content;
  height: fit-content;
  display: flex;
  animation:spin 0.8s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  svg {
    width: 25px;
    height: 25px;
  }
}

//Quill Editor Mentions

.ql-mention-list-container {
  z-index: 44;
  font-family: 'Inter', sans-serif;
}

body:has(.ql-mention-list-container) #custom-mention svg {
  fill: var(--accent-orange) !important;
}

body:not(:has(.NgxEditor__Wrapper[focus='true'])) {
  & div.ql-mention-list-container {
    display: none;
  }
}

#quill-mention-list {
  position: relative;
  z-index: 4;
  padding: 8px;
  margin-top: 5px;
  width: 396px;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid var(--light-gray-inputs-border);
  background: white;
  box-shadow: 0 10px 40px 0 rgba(16, 24, 40, 0.10);

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--light-gray-inputs-border);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-button:start:decrement {
    height: 10px;
    display: block;
    background: transparent;
  }

  &::-webkit-scrollbar-button:end:decrement {
    height: 10px;
    display: block;
    background: transparent;
  }


  li {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    &:hover {
      background: #FAFBFC;
    }

    p {
      color: black;
      font-size: 14px;
      line-height: 1.28;
    }

    span {
      color: var(--primary-gray);
      font-size: 12px;
      line-height: 1.16;
    }
  }
}
ng-scrollbar.remove-overflow-on-tablet  {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .ng-scroll-viewport {
    contain: unset!important;
  }

  @media screen and (min-width: 640px){
    .ng-scroll-viewport-wrapper {
      overflow: visible!important;
    }
  }
}


//.areaStatusActivity {
//  border-radius: 4px;
//  border: 1px solid var(--light-gray-inputs-border);
//  padding: 3px 10px;
//  position: relative;
//  white-space: nowrap;
//
//  &::after {
//    content: url('/assets/icons/arrow-activity-areastatus.svg');
//    display: inline-block;
//    width: 16px;
//    height: 16px;
//    background-color: var(--white);
//
//    position: absolute;
//    right: -21px;
//    top: 3px;
//  }
//
//  &:last-of-type {
//    margin-left: 3px;
//    &::after{
//      display: none;
//    }
//  }
//}

//.activity-item.dashboard:has(b.areaStatusActivity) {
//  height: 75px;
//}


@keyframes spin { 100% { transform:rotate(360deg); } }


.mat-mdc-menu-content {
  padding: 0 !important;
}
