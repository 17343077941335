
.settings-container {
  padding: 24px 32px 32px 32px;

  @media screen and (max-width: 639px) {
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 640px) and (max-width: 1279px){
    padding: 24px 32px 32px 32px;
  }
}
.settings-wrapper {
  border: 1px solid var(--light-gray-inputs-border);
  border-radius: 8px;
  background-color: var(--primary-light-gray);
  margin-bottom: 24px;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid var(--light-gray-inputs-border);
  }

  &__title {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 175%;
    color: var(--black);
    margin: 0 !important;
  }

  &__btn-edit {
    width: 82px;
    display: flex;
    padding: 7px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--light-gray-inputs-border);
    background-color: var(--primary-light-gray);
    color: var(--black);
    transition: background-color 250ms ease-in-out, border 250ms ease-in-out;
    cursor: pointer;

    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.571%;

    & .edit-icon {
      width: 16px;
      height: 16px;
    }

    &:hover {
      border: 1px solid var(--hover-input);
      background-color: var(--white);
    }

    &:focus {
      border: 1px solid var(--primary-dark);
      background: var(--white);

    }

  }

  @media screen and (max-width: 639px){
    margin-bottom: 16px;
    &__header {
      padding: 16px;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
}

.table-details-wrapper {
  padding: 24px;
  background-color: var(--white);

  @media screen and (max-width: 639px){
    padding: 24px 16px;
  }

}

.table-details {
  width: 100%;

  & th {
    text-align: left;
  }

  &__title {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 128.571%;
    color: var(--gray);
    padding: 8px 16px;
    width: 344px;
    text-align: left;
  }

  &__info {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 128.571%;
    color: var(--black);
    padding: 8px 16px;
    max-width: 284px;
    height: 34px;
  }

  &__info--company-type {
    text-transform: capitalize;
  }

  @media screen and (max-width: 639px) {
    &__title {
      padding: 8px 0;
      width: unset;
    }
    &__info {
      width: 165px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 8px 0;
      height: unset;
    }

  }

}

.table-details-info-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .table-details__title {
    width: 120px;
  }

  @media screen and (max-width: 639px) {
    //gap: 24px;
    justify-content: space-between;
  }
  @media screen and (min-width: 640px) {
    gap: 80px;

    .table-details__title {
      width: 140px;
    }
  }

  @media screen and (min-width: 1024px) {
    gap: 130px;

    .table-details__title {
      width: 220px;
    }
  }

}

.btn-delete {
  border-radius: 8px;
  border: 1px solid var(--btn-red);
  background: var( --primary-light-gray);
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
  color: var(--btn-red);
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.571%;
  cursor: pointer;
  white-space: nowrap;

  &__icon {
    width: 16px;
    height: 16px;
    fill: var(--btn-red);
    transform: translateY(3px);
    transition: fill 250ms ease-in-out;
  }

  &:hover {
    border: 1px solid var(--btn-red-hover);
    color: var(--btn-red-hover);

    & .btn-delete__icon {
      fill: var(--btn-red-hover);
    }
  }
  @media screen and (max-width: 639px) {
    align-self: center;
  }
}



//Confirm deleting

.modal-narrow {
  width: 540px;

  display: flex;
  flex-direction: column;
  flex-grow: 0;
  position: relative;
  padding: 0;
  border-radius: 16px;
  margin: auto auto;
  height: fit-content;
  overflow: visible;

  background-color: var(--white);
  transition: opacity 250ms ease-in-out;

  .backdrop[show=true] & {
    opacity: 1;
  }

  &.modalCenter {
    margin: auto;
  }

  @media screen and (max-width: 639px){
    width: 343px;

    & .form-btn-wrapper {
      padding-bottom: 24px !important;
    }
  }
}

.close-modal-deleting {
  top: 28px;
  right: 24px;
}

.confirmDeleting-wrapper {
  padding: 24px;

  @media screen and (max-width: 639px){
    padding-top: 58px;
  }
}

.confirmDeleting-title {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 24px;
  color: var(--black);
  max-width: 450px;

  @media screen and (max-width: 639px){
    text-align: center;
    word-break: break-word;
  }
}

.confirmDeleting-text {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75;
  color: var(--gray);
  margin-bottom: 0;
}

.confirmDeleting-list {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75;
  color: var(--gray);
}



