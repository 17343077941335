.action-btn {
  border: none;
  border-radius: 8px;

  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 128.571%;
  color: var(--white);

  background-color: var(--accent-orange);
  cursor: pointer;

  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: var(--hover-orange);
  }
}
