
.fontFamily {
  font-family: Inter, sans-serif;
}

.text-big {
  @extend .fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
}

.text-standard-medium {
  @extend .fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.text-standard-regular {
  @extend .fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
