
.backdrop {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 150ms ease-in-out, visibility 250ms ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;
  background-color: var(--backdrop);
  &.manual {
    opacity: 0;
    visibility: hidden;
    &[show=true] {
      opacity: 1;
      visibility: visible;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  @media screen and (max-width: 639px){
    height: unset;
    min-height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.modal {
  display: block;
  position: relative;
  padding: 0;
  border-radius: 16px;
  width: 720px;
  margin: auto auto;
  height: fit-content;
  overflow: visible;

  background-color: var(--white);
  transition: opacity 250ms ease-in-out;

  .backdrop[show=true] & {
    opacity: 1;
  }

  &.modalCenter {
    margin: auto;
  }

  @media screen and (max-width: 749px){
    width: 100vw;
    height: 100vh;
    border-radius: unset;
    margin: unset;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }


}

.close-modal-btn {
  position: absolute;
  top: 24px;
  right: 24px;

  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  @media screen and (max-width: 749px){
    right: 16px;
    top: 16px;
  }

  @media screen and (min-width: 750px) {
    height: fit-content;
    max-height: unset;
    &.material-scrollbar-modal ::ng-deep{
      .ng-scrollbar-wrapper,
      .ng-scroll-viewport-wrapper {
        position: unset !important;
      }

      .ng-scrollbar-wrapper[deactivated=false][dir=ltr]>scrollbar-y.scrollbar-control {
        display: none;
      }
      .ng-scroll-viewport {
        position: relative !important;
        overflow: visible !important;
      }
    }
  }
}

.close-modal-btn {
  z-index: 2;
}
.close-modal-icon {
  width: 24px;
  height: 24px;
}

.form-create {
  @media screen and (max-width: 749px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}


.form-inner-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;

  &[paddingTop='true'] {
    padding-top: 0;
  }

  @media screen and (max-width: 749px){
    gap: 24px;
    padding: 16px 16px 24px;
    flex-grow: 1;

    &[paddingTop='true'] {
      padding-top: 0;
    }
  }
}


.main-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 749px){
    gap: 16px
  }
}

.group-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.input-with-error-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  width: 100%;
}

.form-modal-title {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--black);

  margin-right: 30px;
  word-wrap: break-word;

  margin-bottom: 48px;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: -23px;
    top: 52px;
    width: 112%;
    height: 1px;
    background-color: var(--lines-light-gray-border);
  }


  @media screen and (max-width: 749px){
    margin-bottom: 18px;

    &::after {
      top: 44px;
      width: 101vw;
    }
  }
}


.form-modal-way-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  margin-top: 24px;
  margin-left: 24px;
  flex-wrap: wrap;
  width: 630px;

  @media screen and (max-width: 749px){
    width: 390px;
    margin-left: 16px;
    margin-top: 16px;
  }
}

.form-modal-way {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 116.667%;
  color: var(--black);
}

.item-code-way {
  border-radius: 4px;
  background: #DBDFEA;
  padding: 4px 6px;
  font-weight: 500;
  white-space: nowrap;
}


//Modal preview documents

.modal-preview {
  display: block;
  position: relative;
  padding: 0;
  border-radius: 16px;
  width: 70%;
  margin: auto auto;
  height: fit-content;
  overflow: visible;

  background-color: var(--white);
  transition: opacity 250ms ease-in-out;

  .backdrop[show=true] & {
    opacity: 1;
  }

  &.modalCenter {
    margin: auto;
  }

  @media screen and (max-width: 749px){
    width: 100vw;
    height: 100vh;
    border-radius: unset;
    margin: unset;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
}

.modal-preview-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px;

  @media screen and (max-width: 749px){
    padding: 16px;
  }
}

.modal-preview-title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.modal-preview-title {
  color: var(--black);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.571%;
  word-wrap: break-word;
  max-width: 640px;

  @media screen and (max-width: 519px){
    max-width: 215px;
  }

  @media screen and (min-width: 520px) and (max-width: 1279px){
    max-width: 315px;
  }

}

.modal-preview-file-info {
  color: var(--primary-gray);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.667%;

  & span {
    padding-right: 8px;
    margin-right: 8px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -2px;
      height: 100%;
      width: 1px;
      background-color: var(--light-gray-inputs-border);
    }
  }
}

.modal-preview-download-btn {
  border-radius: 8px;
  border: 1px solid var(--light-gray-inputs-border);
  background: var(--primary-light-gray);

  padding: 8px 16px;
  cursor: pointer;
  transition: border 250ms ease-in-out;


  color: var(--black);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.571%;

  & span {
    display: block;
  }

  &:hover {
    border: 1px solid var(--hover-input);
  }

  @media screen and (max-width: 749px) {
    & span {
      display: none;
    }
  }
}



