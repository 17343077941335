.ng-select-wrapper {
  position: relative;
}
form.ng-submitted ng-select.ng-invalid .ng-select-container {
  border-color: var(--error-input);
}
ng-select {
  &.ng-select-filtered, .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }
  .ng-clear-wrapper {
    position: absolute!important;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 22px;
    width: fit-content!important;
    height: 30px;
    span {
      font-size: 30px!important;
    }
  }
  &:has(.ng-clear-wrapper) .ng-arrow-wrapper {
    display: none;
  }
  &.ng-select-opened {
    .ng-select-container {
      border-color: var(--focus-input);
      box-shadow: 0 4px 6px #0e131d14;
    }

    .ng-arrow-wrapper::after {
      transform: rotate(180deg);
    }
  }

  .ng-select-container {
    border-color: var(--focus-input);
    border-radius: 8px;
    border: 1px solid var(--light-gray-inputs-border);
    box-shadow: none;
    transition: border .25s ease-in-out, box-shadow .25s ease-in-out;
    padding: 14px 16px 16px;
    background: #fff;
    color: #333;
    min-height: 42px;
    height: 42px;
    align-items: center;
    box-sizing: border-box;

  }

  &.ng-select-disabled {
    & .ng-select-container {
      border: 1px solid var(--lines-light-gray-border);
      background: var(--primary-light-gray);
      color: var(--primary-gray);

    }
  }

  .ng-value-container {
    padding: 0;
  }

  .ng-placeholder {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--placeholder);
  }

  .ng-input {
    padding-right: 50px;
    left: 15px !important;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: fit-content;
    display: flex;

    input {
      padding-right: 10px!important;
      color: var(--black);
      cursor: text;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin: auto;
    }
  }

  .ng-arrow-wrapper::after {
    content: "";
    background-image: url("../../../../assets/icons/select-arrow-down.svg");
    width: 16px;
    height: 16px;
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transform: rotate(0);
    transition: transform 250ms ease-in-out;
  }

  &.ng-select-disabled .ng-arrow-wrapper::after {
    opacity: 0.5;
  }

  .ng-dropdown-panel {
    top: 107%;
    border-radius: 8px;
    border: 1px solid var(--light-gray-inputs-border);
    box-shadow: 0 10px 40px #1018281a;
    padding: 8px;
    background-color: #fff;

    &:has(.ng-option-label[ng-reflect-ng-item-label*="Add All Users"]) {
      padding-bottom: 58px;

      .ng-dropdown-panel-items {
        position: unset;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--lines-light-gray-border);
      }

      .ng-option:has(.ng-option-label[ng-reflect-ng-item-label*="Add All Users"]) {
        position: absolute;
        bottom: 8px;
        width: calc(100% - 22px);
      }
    }

    &:has(.ng-dropdown-footer) {
      padding-bottom: 62px;
      .ng-dropdown-panel-items {
        position: unset;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--lines-light-gray-border);
      }
      & .ng-dropdown-footer {
        position: absolute;
        bottom: 8px;
        left: 9px;
        width: calc(100% - 22px);
        cursor: pointer;

        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 128.571%;

        &:hover {
          background-color: var(--bg-option);
        }

        & a {
          padding: 12px 8px;
          display: block;
          width: 100%;
        }
      }
    }

    &:has(a[hasUsers='false']) {
      padding: 8px;

      & .ng-dropdown-panel-items {
        padding: unset;
        border: none;
      }

      & .ng-dropdown-footer {
        display: none;
      }
    }

  }

  .ng-dropdown-panel-items {
    padding-right: 6px;
    border-radius: 8px;
    max-height: 260px !important;

    &::-webkit-scrollbar {
      width: var(--scrollbar-width);
      border-radius: 8px;
      margin-right: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--light-gray-inputs-border);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .ng-option {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 128.571%;
    height: 42px;
    padding: 12px 8px;

    &.ng-option-marked {
      background-color: #f5faff;
      color: #333;
    }

    &:hover {
      background-color: var(--bg-option);
    }

    &.ng-option-selected {
      background-color: var(--bg-option);

      span {
        font-weight: 600;
        color: #333;
      }

      &:hover {
        background-color: var(--bg-option);
      }
    }


    &.ng-option-disabled {
      color: var(--primary-gray);
      padding-left: 0;

      &:hover {
        background-color: unset;
        cursor: auto;
      }
    }
  }

  .ng-value-label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &.ng-select-multiple {
    .ng-select-container.ng-has-value {
      padding: 8px 16px 8px 8px;
      height: unset;
    }
    .ng-value-container {
      gap: 4px;

      & + .ng-arrow-wrapper::after {
        right: 4px;
      }

      @media screen and (max-width: 639px){
        max-width: 441px;

        & + .ng-arrow-wrapper::after {
          right: -18px;
        }
      }

      @media screen and (max-width: 450px){
        max-width: 280px;
        & + .ng-arrow-wrapper::after {
          right: -30px;
        }

      }
    }
    .ng-value {
      padding: 4px 32px 4px 8px;
      background-color: var(--white);
      border-radius: 4px;
      border: 1px solid var(--light-gray-inputs-border);
      color: var(--black);
      position: relative;
    }
    .ng-value-label{
      display: flex;
      align-items: center;
    }
    .ng-value-icon {
      font-weight: 600;
      position: absolute;
      font-size: 23px;

      margin: auto 0;
      right: 10px;
      top: 0;
    }

    &[item-not-clearable] .ng-select-container > .ng-value-container > div:nth-child(2) {
      background-color: var(--white);
      border-radius: 4px;
      border: 1px solid var(--light-gray-inputs-border);

      color: var(--black);
      padding-right: 12px;
      .ng-value-icon {
        display: none!important;
      }
    }
  }
}

.ng-select-wrapper--wider {
  width: 100%;
}
